@import '../../Styles/variables';

.loader_container {
	background-color: $primary_black;
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	& > img {
		height: 60px;
	}
}
